import Vue from 'vue'
import VueRouter, {RouteConfig} from 'vue-router'

Vue.use(VueRouter)


const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Start',
        component: () => import('../views/Start.vue'),
        meta: {
            breadcrumb: 'Start'
        }
    },
    {
        path: '/cards',
        name: 'Cards',
        component: () => import('../views/Cards.vue'),
        meta: {
            breadcrumb: 'Cards'
        }
    },
    {
        path: '/tags',
        name: 'Tags',
        component: () => import('../views/Tags.vue'),
        meta: {
            breadcrumb: 'Tags.vue'
        }
    },
    {
        path: '/multipatternsolution',
        name: 'MultiPatternSolution',
        component: () => import('../views/MultiPatternSolution.vue'),
        meta: {
            breadcrumb: 'MultiPatternSolution'
        }
    },
    {
        path: '/feedback',
        name: 'Feedback',
        component: () => import('../views/Feedback.vue'),
        meta: {
            breadcrumb: 'Feedback'
        },
        props: (route) => ({ pattern: route.query.pattern}),
    },
    {
        path: '/patterns',
        name: 'Patterns',
        component: () => import('../components/Pattern.vue'),
        meta: {
            breadcrumb: 'Pattern'
        }
    },
    {
        // currently unused
        path: '/home',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        meta: {
            breadcrumb: 'Home'
        }
    },
    {
        path: '/about',
        name: 'About',
        component: () => import('../views/About.vue'),
        meta: {
            breadcrumb: 'About'
        }
    },

    {
        // currently unused
        path: '/control',
        name: 'Control',
        component: () => import('../views/ControlRoom.vue'),
        meta: {
            breadcrumb: 'Control Room'
        }
    },
    {
        path: '/connections',
        name: 'Connections',
        component: () => import('../views/Chord.vue'),
        meta: {
            breadcrumb: 'Relationships'
        }
    }
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
