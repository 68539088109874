import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'


Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: localStorage
  })],
  strict: true,
  state: {
    root: "https://hci.hochschule-trier.de:8443",
    // root: "http://localhost:8080",
    isLogged: false,
    isOpen: false,
    firstFeedback: true,
    // TODO: DELETE Zeile 19-20 und 32-34
    feedbackArray: [0] as any,
  },
  mutations: {
    statusLogin(state, newState: boolean){
      state.isLogged = newState
    },
    toggleComments(state, newState: boolean){
      state.isOpen = newState
    },
    feedbackGiven(state, newState: boolean){
      state.firstFeedback = newState
    },
    feedback(state, newState: number){
      state.feedbackArray.push(newState)
    }
  },
  actions: {
  },
  modules: {
  },
})
