





























































import {Component} from 'vue-property-decorator';
import Vue from 'vue'
import ModalLogin from '@/components/ModalLogin.vue'
import CountryFlag from 'vue-country-flag'


@Component({
  components: {
    ModalLogin, CountryFlag
  }
})

export default class Navbar extends Vue {

  private openLoginModal() {
    this.$buefy.modal.open({
      parent: this,
      component: ModalLogin,
      trapFocus: true
    })
  }

  private logout() {
    this.$store.commit('statusLogin', false)
    this.$buefy.toast.open({
      message: 'Logged out',
      position: 'is-top',
      type: 'is-info'
    })
  }


}
