














import {Component, Vue} from 'vue-property-decorator';
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'


@Component({
      components: {
        Navbar,
        Footer
      }
    }
)
export default class App extends Vue {
  private patternData = [] as any
  private addMode = false

  private passPatternData(nameAndOrigin: any){
    this.patternData = nameAndOrigin
  }

  private turnAddModeOn(){
    this.addMode = true
    this.patternData = []
  }
}

