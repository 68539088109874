

































import Vue from 'vue'
import {Component} from "vue-property-decorator";

@Component({
      components: {}
    }
)
export default class ModalLogin extends Vue {

  private password = ""

  private async login() {

    if (this.password != "") {
      const pw = this.password
      Vue.axios.post(`${this.$store.state.root}/login`, pw, {
        headers: {
          'Content-Type': 'text/plain'
        }
      })
            .then(resp => {
            this.$store.commit('statusLogin', resp.data)
              if(resp.data == false){
                this.$buefy.toast.open({
                  message:'Ooops! Wrong Password',
                  position:'is-top',
                  type:'is-warning'
                })
              } else {
                this.$buefy.toast.open({
                  message:'Logged in',
                  position:'is-top',
                  type:'is-success'
                })
                this.$emit('close')
              }
          })

    }
  }
}

